<template>
    <sys-register-base 
        :loading="loadingSaving" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon"
        v-bind:show.sync="dialog"
        maxWidth="60%"
        textButtonSave="Salvar"
    >
        <template v-slot:form>
            <v-form
                ref="form"
            >
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-data-table
                                    v-model="selected"
                                    :headers="headers"
                                    :items="portfolios"
                                    :single-select="false"
                                    show-select
                                    class="elevation-1"
                                    :loading="loading"
                                >
                                       <template v-slot:item.day_of_week_id="{ item }">
                                            <sys-select-day-of-week
                                                :model.sync="item.day_of_week_id"
                                                :error="errors.length > 0"  
                                                :errors="errors.day_of_week_id"
                                                label=""
                                                :filter="days"
                                                :disabled="days.length == 0"
                                                item-text="initials"
                                                clearable
                                            />
                                        </template>

                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
             </v-form>
            <v-alert v-if="errors.length > 0" type="error">
                <ul>
                    <li :key="error" v-for="error in errors">
                    {{ error }}
                    </li>
                </ul>
            </v-alert>

            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
            
        </template>
    </sys-register-base>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
    name: "SysRegisterGridPortfolio",
    data: (vm) => ({
        dialog: false,
        errors: {},
        portfolios: [],
        message: '',
        error: false,
        loading: false,
        days: [],
        loadingSaving: false,
        selected: [],
        headers: [
          { text: 'Nome',       align: 'start',     sortable: true,     value: 'name', width: '35%' },
          { text: 'Empresa',    align: 'start',     sortable: true,     value: 'company_name', width: '30%' },
          { text: 'Dia',        align: 'center',    sortable: false,    value: 'day_of_week_id', width: '15%' },
          { text: 'Local',      align: 'center',    sortable: false,    value: 'is_local', width: '10%' },
          { text: 'Atemporal',  align: 'center',    sortable: false,    value: 'timeless', width: '10%' },
        ],
    }),
    computed: {
      ...mapState('auth', ['acesso']),
    },
    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                
                this.errors = {};
                this.message = '';
                this.error = false;

                this.loadGrid();
                this.loadPortfolios();
                
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        models: {

        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            default: "Vínculo Empresa Parceira"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
    },
    methods:{
        ...mapActions('grid', ['ActionFindGridById', 'ActionIndexGridOpportunities', 'ActionUpdateOfferItemPortfolios', 'ActionIndexOfferItemPortfolios']),
        
        loadGrid(){

            let payload = {
                id: this.model.id,
                with: 'classes'
            };

            this.days = [];
            
            this.ActionFindGridById(payload)
                .then((res) => {
                    
                    res.data.classes.forEach(c => {

                        if(!this.days.includes(c.day_of_week_id)){
                            this.days.push(c.day_of_week_id);
                        }

                    });
                   
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                   
                })

        },  
        
        loadPortfolios(){

            this.portfolios = [];
            this.selected = [];
            this.loading = true;
            
            let payload = {
                grid_id: this.model.id
            };

            this.ActionIndexGridOpportunities(payload)
                .then((res) => {
                    
                    res.data.forEach(item => {

                        item.companies.forEach(company => {
                            this.portfolios.push({
                                id:                         item.id + '|' + company.id,
                                discipline_portfolio_id:    item.id,
                                partner_company_id:         company.id,
                                day_of_week_id:             null,
                                name:                       item.name,
                                company_name:               company.name,
                                timeless:                   item.timeless ? 'Sim' : 'Não',
                                is_local:                   item.is_local ? 'Sim' : 'Não',
                            });
                        });

                    });
                    
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    this.loadOfferItemPortfolios();
                })

        },

        loadOfferItemPortfolios(){

            let payload = {
                find_columns: {
                    grid_id:  this.model.id,
                    active:         1
                }
            };

            this.ActionIndexOfferItemPortfolios(payload)
                .then((res) => {
                    
                    res.data.forEach(item => {

                        let portfolio = this.portfolios.find(p => (p.discipline_portfolio_id == item.discipline_portfolio_id && p.partner_company_id == item.partner_company_id));

                        if(portfolio){
                            portfolio.day_of_week_id        = item.day_of_week_id;
                            this.selected.push(portfolio);
                        }

                    });

                })
                .catch((error) =>{
                
                    
                })
                .finally(() => {
                    this.loading = false;
                })

        },

        save(){

            this.loadingSaving = true;
            this.message = '';
            this.error = false;

            let payload = {
                id: this.model.id,
                byGrid: true,
                items: [],
            };

            this.selected.forEach(item => {

                payload.items.push({
                    discipline_portfolio_id:    item.discipline_portfolio_id,
                    partner_company_id:         item.partner_company_id,
                    day_of_week_id:             item.day_of_week_id,
                });

            });

            this.ActionUpdateOfferItemPortfolios(payload)
                .then((res) => {
                    this.message = res.message
                   
                })
                .catch((error) =>{
                    this.message = error.message
                    this.error = true;
                })
                .finally(() => {
                    this.loadingSaving = false;
                })

        },
        
    }
}
</script>